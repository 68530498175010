export const MATRIX_SERVER_URL = 'https://testmatrix.spicter.com';
export const REDIRECT_URI = `https://testadmin.mydoc.spicter.io`;
export const SCOPE = encodeURIComponent('offline_access openid profile');
export const REALM_NAME = 'test-mydoc';
export const LOGIN_URL = `${MATRIX_SERVER_URL}/_matrix/client/v3/login/sso/redirect/oidc-${REALM_NAME}?redirectUrl=${REDIRECT_URI}&org.matrix.msc3824.action=login&scope=${SCOPE}`;
export const CLIENT_ID = 'login';
export const ENDPOINT_URL = 'https://iam.spicter.io/realms/test-mydoc/protocol/openid-connect';
export const LOGOUT_URL = `${ENDPOINT_URL}/logout?post_logout_redirect_uri=${REDIRECT_URI}&&client_id=${CLIENT_ID}`;
export const CLIENT_SECRET = 'Jxf2VF7LmC7fgUzLpLW9tUM10eTgrgEJ';
export const KC_LOGIN_CLIENTID = 'admin-login'
