import { DoctorVerificationList } from 'data';
import { API, axiosInstance, handleResponse } from './helper';
import { getRealmUserInfo } from './keycloak';
import { Doctor } from '../pages/DoctorsVerification/types';

interface updateVerificationStatusPayload {
  status: string;
  practiseAdminId: string;
}

interface updateOverAllStatusPayload {
  status: string;
  practiseAdminId: string;
}

interface DoctorVerificationStatus {
  status: string;
  practiseAdminId: string; // UUID for the practice admin
  feedback: string; // Feedback or comments from the reviewer
  reviewerId: string; // UUID for the reviewer
  section: string; // Section or context of the status (e.g., "Reject Profile")
}

const getDoctorVerificationList = (verificationStatus: string) =>
  handleResponse(
    axiosInstance.get(`${API.getDoctorVerificationList}?verificationStatus=${verificationStatus}`)
  );

const getDoctorDetails = (practiseAdminId: string) => {
  return handleResponse(
    axiosInstance.get(`${API.getDoctorDetails}?practiseAdminId=${practiseAdminId}`)
  );
};

const getDoctorVerificationStats = () =>
  handleResponse(axiosInstance.get(API.getDoctorVerificationStats));

const updateProfessionalDetailsVerificationStatus = (
  update_verification_status_payload: updateVerificationStatusPayload
) =>
  handleResponse(
    axiosInstance.put(
      API.updateProfessionalDetailsVerificationStatus,
      update_verification_status_payload
    )
  );

const updateIdDocumentsVerificationStatus = (
  update_id_status_payload: updateVerificationStatusPayload
) =>
  handleResponse(
    axiosInstance.put(API.updateIdDocumentsVerificationStatus, update_id_status_payload)
  );

const updateLivePictureVerificationStatus = (
  update_live_pic_status_payload: updateVerificationStatusPayload
) =>
  handleResponse(
    axiosInstance.put(API.updateLivePictureVerificationStatus, update_live_pic_status_payload)
  );

const updateOrgVerificationStatus = (update_org_status_payload: updateVerificationStatusPayload) =>
  handleResponse(axiosInstance.put(API.updateOrgVerificationStatus, update_org_status_payload));

const askForUpdateProfessionalDetails = (
  ask_for_update_verification_status_payload: updateVerificationStatusPayload
) =>
  handleResponse(
    axiosInstance.post(
      API.askForUpdateProfessionalDetails,
      ask_for_update_verification_status_payload
    )
  );

const askForUpdateMedicalDocument = (update_id_status_payload: updateVerificationStatusPayload) =>
  handleResponse(axiosInstance.post(API.askForUpdateMedicalDocument, update_id_status_payload));

const askForUpdateLivePicture = (update_live_pic_status_payload: updateVerificationStatusPayload) =>
  handleResponse(axiosInstance.post(API.askForUpdateLivePicture, update_live_pic_status_payload));

const askForUpdateOrganisationDetails = (
  update_org_status_payload: updateVerificationStatusPayload
) =>
  handleResponse(
    axiosInstance.post(API.askForUpdateOrganisationDetails, update_org_status_payload)
  );

const updatePractiseOverAllVerificationStatus = (
  update_over_all_status_payload: updateOverAllStatusPayload
) =>
  handleResponse(
    axiosInstance.put(API.updatePractiseOverAllVerificationStatus, update_over_all_status_payload)
  );

const rejectProfile = (reject_payload: DoctorVerificationStatus) =>
  handleResponse(axiosInstance.post(API.rejectProfile, reject_payload));

// Utility function to format doctors list
async function getFormattedDoctorsList(
  selectedStat: string | undefined,
  list: DoctorVerificationList
): Promise<Doctor[]> {
  if (!list?.practiseBasicDetails?.length) return [];

  // Filter the list based on the selected status
  const filteredList = selectedStat
    ? list.practiseBasicDetails.filter((item) => item?.verificationStatus === selectedStat)
    : list.practiseBasicDetails;

  // Fetch doctor info for each filtered entry
  const doctorPromises = filteredList.map(async (doc) => {
    try {
      const { id, email, firstName, lastName } = await getRealmUserInfo(doc.practiseAdminId);
      return { ...doc, id, email, firstName, lastName };
    } catch (error) {
      console.warn(`Failed to fetch data for admin ID ${doc.practiseAdminId}:`, error);
      return null;
    }
  });

  // Resolve all promises and filter out null results
  const formattedDoctors = (await Promise.all(doctorPromises)).filter(Boolean);
  return formattedDoctors as Doctor[];
}

export {
  getDoctorVerificationList,
  getDoctorDetails,
  getDoctorVerificationStats,
  updateProfessionalDetailsVerificationStatus,
  updateIdDocumentsVerificationStatus,
  updateLivePictureVerificationStatus,
  updateOrgVerificationStatus,
  updatePractiseOverAllVerificationStatus,
  rejectProfile,
  askForUpdateProfessionalDetails,
  askForUpdateMedicalDocument,
  askForUpdateLivePicture,
  askForUpdateOrganisationDetails,
  getFormattedDoctorsList
};
