import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { Provider } from 'react-redux';
import store from 'data/store';
import { exchangeCodeForToken, getKeycloakLoginUrl } from './services';

const handleKeycloakRedirect = async (): Promise<string | null> => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('session_state');

  if (code && state) {
    try {
      const token = await exchangeCodeForToken(code);

      if (token) {
        // Save the token to sessionStorage
        sessionStorage.setItem('authToken', token);

        // Clear query parameters from the URL
        window.history.replaceState({}, document.title, window.location.pathname);

        return token;
      } else {
        console.error('Token is null or undefined');
        return null;
      }
    } catch (error) {
      console.error('Error exchanging code for token:', error);
      return null; // Do not redirect to Keycloak on error
    }
  } else {
    // Check if there's an existing token in sessionStorage
    const existingToken = sessionStorage.getItem('authToken');
    if (!existingToken) {
      // Redirect to Keycloak login if no token exists
      const keycloakLoginUrl = getKeycloakLoginUrl();
      window.location.href = keycloakLoginUrl;
    }
    return existingToken; // existingToken can be null
  }
};

(async () => {
  const token = await handleKeycloakRedirect();
  if (token) {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App token={token} />
        </Provider>
      </React.StrictMode>
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
