import axios from 'axios';
import { Practise, Role } from '../utils';
import { CLIENT_ID, CLIENT_SECRET, KC_LOGIN_CLIENTID, REDIRECT_URI } from '../config';

const IAM_BASE_URL = 'https://iam.spicter.io';
const REALM_NAME = 'realms/test-mydoc';

export const KC_TOKEN_ENDPOINT = `${IAM_BASE_URL}/${REALM_NAME}/protocol/openid-connect/token`;
export const REALMS_URL = `${IAM_BASE_URL}/admin/${REALM_NAME}`;
export const REALMS_USERS_URL = `${REALMS_URL}/users`;

export const GROUPS_URL = `${IAM_BASE_URL}/admin/${REALM_NAME}/groups`;
export const REALM_ROLES = `${IAM_BASE_URL}/admin/${REALM_NAME}/roles`;

// const REDIRECT_URI = 'http://localhost:3000'; 

export const exchangeCodeForToken = async (code: string): Promise<string> => {
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('code', code);
  params.append('redirect_uri', REDIRECT_URI);
  params.append('client_id', KC_LOGIN_CLIENTID);
  params.append('client_secret', '8j9aPVGrDuRRhB5IbNIAJLqmAElMcwj2');

  try {
    const response = await axios.post(KC_TOKEN_ENDPOINT, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data.access_token;
  } catch (error) {
    console.error('Error exchanging code for token:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};



// Generate the login URL
export const getKeycloakLoginUrl = (): string => {
  return `${IAM_BASE_URL}/${REALM_NAME}/protocol/openid-connect/auth?client_id=${KC_LOGIN_CLIENTID}&redirect_uri=${encodeURIComponent(
    REDIRECT_URI
  )}&response_type=code&scope=openid`;
};

export const getRealmToken = async () => {
  try {
    const formData = {
      grant_type: 'client_credentials',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET
    };
    const response = await axios.post(KC_TOKEN_ENDPOINT, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    const data = await response.data;
    localStorage.setItem('access_token', data?.access_token);
    return data.access_token;
  } catch (error) {
    console.log('🚀 ~ file: auth.ts:216 ~ getRealmToken ~ error:', error);
    return null;
  }
};

export const getRealmUserInfo = async (id: string) => {
  try {
    if (!id) {
      return;
    }
    const token = await getRealmToken();

    const response = await axios.get(`${REALMS_USERS_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await response.data;
    return data || {};
  } catch (error) {
    console.log('🚀 ~ file: auth.ts:232 ~ getRealmUserInfo ~ error:', error);
    return null;
  }
};

export const getAllGroups = async () => {
  try {
    const token = await getRealmToken();

    const response = await axios.get(`${GROUPS_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await response.data;
    return data || {};
  } catch (error) {
    console.log('🚀 ~ getGrpups ~ error:', error);
    return null;
  }
};

export const getAllPractiseList = async (groupId: string) => {
  try {
    const token = await getRealmToken();

    const response = await axios.get(`${GROUPS_URL}/${groupId}/children`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await response.data;
    return data || {};
  } catch (error) {
    console.log('🚀 ~ getAllPractiseList ~ error:', error);
    return null;
  }
};

export const getRealmRoles = async () => {
  try {
    const token = await getRealmToken();

    const response = await axios.get(`${REALM_ROLES}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await response.data;
    return data || {};
  } catch (error) {
    console.log('🚀 ~ getRealmRoles ~ error:', error);
    return null;
  }
};

export const assignRoleToUser = async (userId: string, body: Role[]) => {
  try {
    const token = await getRealmToken();
    const response = await axios.post(
      `${REALMS_USERS_URL}/${userId}/role-mappings/realm`,
      body, // This is the request body
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    const data = await response.data;
    return data || {};
  } catch (error) {
    console.log('🚀 ~ assignRoleToUser ~ error:', error);
    return null;
  }
};

export const addOrgnisationOrPractise = async (groupId: string, body: Practise) => {
  try {
    const token = await getRealmToken();
    const response = await axios.post(
      `${REALMS_URL}/groups/${groupId}/children`,
      body, // This is the request body
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    const data = await response.data;
    return data || {};
  } catch (error) {
    console.log('🚀 ~ addOrgnisationOrPractise ~ error:', error);
    return null;
  }
};

export const addUserToGroup = async (userId: string, groupId: string) => {
  try {
    const token = await getRealmToken();
    const response = await axios.put(`${REALMS_USERS_URL}/${userId}/groups/${groupId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const data = await response.data;
    return data || {};
  } catch (error) {
    console.log('🚀 ~ addUserToGroup ~ error:', error);
    return null;
  }
};
