export const fonts = {
  primary: "'Roboto', sans-serif",
  secondary: "'Arial', sans-serif",
  header: "'Montserrat', sans-serif"
};

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  large: 600,
  bold: 700,
  extraBold: 900
};
