import { handleResponse, axiosInstance, API } from './helper';

interface RoleDetails {
  name: string;
  id: string;
}
const getRoleManagementStats = () => handleResponse(axiosInstance.get(API.getRoleManagementStats));

const getUserDetailsRoleManagement = () =>
  handleResponse(axiosInstance.get(API.getUserDetailsRoleManagement));

export { getRoleManagementStats, getUserDetailsRoleManagement };
