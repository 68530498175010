import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import './assets/fonts/fonts.css';
import './App.css';
import './components/loading/index.css';
import Navbar from 'components/header';
import routeData from './../src/config/routes.json';
import PageNotFound from './pages/PageNotFound';
import loadingIndicator from './assets/animation/loadingIndicator.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { getKeycloakLoginUrl } from './services';

// Type for dynamically imported components
type PageComponentType = React.ComponentType;

const loadPage = (pageName: string): PageComponentType => {
  switch (pageName) {
    case 'dashboard':
      return React.lazy(() => import('./pages/Dashboard'));
    case 'doctorManagements':
      return React.lazy(() => import('./pages/DoctorManagement'));
    case 'clinicManagement':
      return React.lazy(() => import('./pages/ClinicManagement'));
    case 'patientManagement':
      return React.lazy(() => import('./pages/PatientManagement'));
    case 'demographics':
      return React.lazy(() => import('./pages/Demographics'));
    case 'appPerformance':
      return React.lazy(() => import('./pages/AppPerformance'));
    case 'specialisationManager':
      return React.lazy(() => import('./pages/SpecialisationManager'));
    case 'chat':
      return React.lazy(() => import('./pages/Chat'));

    case 'doctorsVerification':
      return React.lazy(() => import('./pages/DoctorsVerification'));

    case 'doctorView':
      return React.lazy(() => import('./pages/DoctorsVerification/View'));

    case 'viewDoctor':
      return React.lazy(() => import('./pages/DoctorsVerification'));

    case 'organisationManagement':
      return React.lazy(() => import('./pages/OrganisationManagement'));
    case 'roleManagement':
      return React.lazy(() => import('./pages/RoleManagement'));
    case 'doctorDetails':
      return React.lazy(() => import('./pages/DoctorDetails'));

    default:
      return PageNotFound; // Fallback component
  }
};

interface AppProps {
  token: string | null;
}

function App({ token }: AppProps) {
  const [authToken, setAuthToken] = useState<string | null>(token);

  useEffect(() => {
    const storedToken = sessionStorage.getItem('authToken');
    if (!storedToken) {
      const keycloakLoginUrl = getKeycloakLoginUrl();
      window.location.href = keycloakLoginUrl;
    } else {
      setAuthToken(storedToken);
    }
  }, []);

  if (!authToken) {
    return (
      <div className={'loaderWrapper'}>
        <Player loop src={loadingIndicator} className={'loaderSize'} />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Box className="app-container">
        <Box component="header" className="header">
          <Navbar />
        </Box>
        <Grid container className="main-content">
          <Grid item xs={12} className="content-area">
            <Grid item xs={12} className="routes">
              <React.Suspense
                fallback={
                  <div className={'loaderWrapper'}>
                    <Player loop src={loadingIndicator} className={'loaderSize'} />
                  </div>
                }>
                <Routes>
                  {routeData.map((route) => {
                    const PageComponent = loadPage(route.name);

                    if (Array.isArray(route.path)) {
                      return route.path.map((path) => (
                        <Route key={path} path={path} element={<PageComponent />} />
                      ));
                    } else {
                      return (
                        <Route key={route.path} path={route.path} element={<PageComponent />} />
                      );
                    }
                  })}

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </React.Suspense>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </BrowserRouter>
  );
}

export default App;
