import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getDoctorVerificationStats,
  getDoctorVerificationList,
  getDoctorDetails,
  getFormattedDoctorsList
} from '../../services/doctor-verification';
import { DoctorVerificationList, DoctorVerificationState, VerificationStats } from 'data/types';
import { createApiThunk } from 'data/helper';
import { getRealmUserInfo } from '../../services';
import { Doctor, DoctorDetails, KeycloakUser } from '../../pages/DoctorsVerification/types';
import { VERIFICATION_STATUS } from '../../constants';

const initialState: DoctorVerificationState = {
  selectedDoctor: null,
  list: {
    practiseBasicDetails: []
  },
  doctors: [],
  user: {
    firstName: '',
    lastName: '',
    email: ''
  },
  verificationStats: {
    pendingVerification: {
      aggregate: {
        count: 0
      }
    },
    verifiedPractices: {
      aggregate: {
        count: 0
      }
    },
    rejectedVerification: {
      aggregate: {
        count: 0
      }
    },
    reVerification: {
      aggregate: {
        count: 0
      }
    }
  },
  verificationDetails: null,
  loading: {
    list: false,
    stats: false,
    userInfo: false,
    verificationDetails: false,
    doctors: false
  },
  error: null,
  selectedTab: VERIFICATION_STATUS.UNVERIFIED
};

// Create the thunk using createApiThunk
export const fetchUserInfo = createApiThunk(
  'doctorVerification/fetchUserInfo',
  async (id?: string) => getRealmUserInfo(id as string) // Pass the API call function
);

// Create an async thunk for fetching doctor List
export const fetchDoctorVerificationList = createApiThunk(
  'doctorVerification/fetchDoctorVerificationList',
  async (status?: string) => getDoctorVerificationList(status as string)
);

// Create an async thunk for fetching doctor details
export const fetchDoctorVerificationDetails = createApiThunk(
  'doctorVerification/fetchDoctorVerificationDetails',
  async (id?: string) => getDoctorDetails(id as string)
);

export const fetchDoctorVerificationStats = createApiThunk(
  'doctorVerification/fetchDoctorVerificationStats',
  getDoctorVerificationStats
);

export const fetchFormattedDoctorVerificationList = createAsyncThunk<
  Doctor[], // Return type
  { selectedStat: string | undefined; list: DoctorVerificationList }, // Argument type
  { rejectValue: string } // Rejection type
>(
  'doctorVerification/fetchFormattedDoctorVerificationList',
  async ({ selectedStat, list }, { rejectWithValue }) => {
    try {
      return await getFormattedDoctorsList(selectedStat, list);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error fetching formatted doctor list:', error);
      return rejectWithValue(error?.message || 'Failed to fetch doctor list.');
    }
  }
);

const doctorVerificationSlice = createSlice({
  name: 'doctorVerification',
  initialState,
  reducers: {
    setVerificationLoading: (state, action) => {
      state.loading.list = action.payload;
    },

    setDoctorDetails: (state) => {
      state.verificationDetails = null;
    },

    setSelectedDoctor: (state, action) => {
      state.selectedDoctor = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorVerificationList.pending, (state) => {
        state.loading.list = true; // Correctly updating the list loading state
        state.error = null;
      })
      .addCase(
        fetchDoctorVerificationList.fulfilled,
        (state, action: PayloadAction<DoctorVerificationList>) => {
          state.list = action.payload;
          state.loading.list = false; // Correctly updating the list loading state
        }
      )
      .addCase(fetchDoctorVerificationList.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading.list = false; // Correctly updating the list loading state
      })

      .addCase(fetchDoctorVerificationStats.pending, (state) => {
        state.loading.stats = true; // Correctly updating the stats loading state
        state.error = null;
      })
      .addCase(
        fetchDoctorVerificationStats.fulfilled,
        (state, action: PayloadAction<VerificationStats>) => {
          state.verificationStats = action.payload;
          state.loading.stats = false; // Correctly updating the stats loading state
        }
      )
      .addCase(fetchDoctorVerificationStats.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading.stats = false; // Correctly updating the stats loading state
      })

      .addCase(fetchUserInfo.pending, (state) => {
        state.loading.userInfo = true; // Correctly updating the userInfo loading state
        state.error = null;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<KeycloakUser>) => {
        state.user = action.payload;
        state.loading.userInfo = false; // Correctly updating the userInfo loading state
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading.userInfo = false; // Correctly updating the userInfo loading state
      })

      .addCase(fetchDoctorVerificationDetails.pending, (state) => {
        state.loading.verificationDetails = true; // Correctly updating the verificationDetails loading state
        state.error = null;
      })
      .addCase(
        fetchDoctorVerificationDetails.fulfilled,
        (state, action: PayloadAction<DoctorDetails>) => {
          state.verificationDetails = action.payload;
          state.loading.verificationDetails = false; // Correctly updating the verificationDetails loading state
        }
      )
      .addCase(fetchDoctorVerificationDetails.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading.verificationDetails = false; // Correctly updating the verificationDetails loading state
      })

      //fetchFormattedDoctorVerificationList
      .addCase(fetchFormattedDoctorVerificationList.pending, (state) => {
        state.loading.doctors = true;
        state.error = null;
      })
      .addCase(fetchFormattedDoctorVerificationList.fulfilled, (state, action) => {
        state.loading.doctors = false;
        state.doctors = action.payload;
      })
      .addCase(fetchFormattedDoctorVerificationList.rejected, (state, action) => {
        state.loading.doctors = false;
        state.error = action.payload || 'Failed to fetch doctor data.';
      });
  }
});

export const { setVerificationLoading, setDoctorDetails, setSelectedDoctor, setSelectedTab } =
  doctorVerificationSlice.actions;

export default doctorVerificationSlice.reducer;
