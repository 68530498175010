import { createSelector } from 'reselect';
import { RoleManagementState, RootState } from 'data'; // Update with your actual import paths

// Base selector for role-management state
const roleManagementSelector = (state: RootState) => state.roleManagement;

/**
 * Helper function to create memoized selectors for role-management fields
 * @param field - The field to select
 */
const createRoleManagementSelector = <T extends keyof RoleManagementState>(field: T) =>
  createSelector([roleManagementSelector], (roleManagementState) => roleManagementState[field]);

// Export specific selectors
export const selectRoleManagementList = createRoleManagementSelector('list');
export const selectRoleManagementStats = createRoleManagementSelector('roleManagementStats');
export const selectRoleManagementLoading = createRoleManagementSelector('loading');
export const selectRoleManagementError = createRoleManagementSelector('error');
