export const ICON_TYPE = {
  PLUS: 'plus',
  ARROW_DOWN: 'arrow-down',
  ARROW_RIGHT: 'arrow-right',
  DOUBLE_TICK: 'double-tick',
  SINGLE_TICK: 'single-tick',
  DOWNLOAD: 'download',
  ATTACHMENT: 'attachment',
  LOADING: 'loading',
  REFRESH: 'refresh',
  THREEDOT: 'dots',
  DNA2: 'dna2',
  EMPATHIZE: 'empathize',
  STETHOSCOPE: 'stethoscope',
  ARROW_LEFT: 'arrow-left',
  MAN: 'man',
  CHECKUPLIST: 'checkup-list',
  DISCOUNTCHECK: 'rosette-discount-check',
  CIRCLEPLUS: 'circle-plus'
};
