import { createSlice } from '@reduxjs/toolkit';

import { RoleManagementState } from 'data/types';
import { createApiThunk } from 'data/helper';
import {
  getRoleManagementStats,
  getUserDetailsRoleManagement
} from '../../services/role-management';

const initialState: RoleManagementState = {
  list: {
    practiseBasicDetails: []
  },
  roleManagementStats: {
    practiseAdminUsersCount: {
      aggregate: {
        count: 0
      }
    },
    practiseIndividualUsersCount: {
      aggregate: {
        count: 0
      }
    },
    practiseAllUsersCount: {
      aggregate: {
        count: 0
      }
    }
  },
  loading: false,
  error: null
};

// Create an async thunk for fetching doctor details
export const fetchRoleManagementList = createApiThunk(
  'roleManagement/fetchRoleManagementList',
  getUserDetailsRoleManagement
);

export const fetchRoleManagementStats = createApiThunk(
  'roleManagement/fetchRoleManagementStats',
  getRoleManagementStats
);

const roleManagementSlice = createSlice({
  name: 'roleManagement',
  initialState,
  reducers: {
    setRoleManagementLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleManagementList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoleManagementList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoleManagementList.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })

      .addCase(fetchRoleManagementStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoleManagementStats.fulfilled, (state, action) => {
        state.roleManagementStats = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoleManagementStats.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});
export const { setRoleManagementLoading } = roleManagementSlice.actions;

export default roleManagementSlice.reducer;
