export const API_ROUTES = {
  getAdditionalQualifications: 'get-additional-qualifications',
  getService: 'get-services',
  insertFocusQualifications: 'insert-focus-qualifications',
  updateSpeciality: 'update-speciality',
  insertQualifications: 'insert-qualifications',
  getSpecialistQualificationList: 'get-specialist-qualification-list',
  getDoctorManagementDetails: 'get-doctor-management-details',
  deleteQualifications: 'delete-qualifications',
  insertAdditionalQualifications: 'insert-additional-qualifications',
  insertTreatmentService: 'insert-billable-services',
  getTreatmentQualifications: 'get-treatment-qualifications',
  deleteBillableServices: 'delete-billable-services',
  updateServiceQualifications: 'update-service-qualifications',
  getDoctorVerificationList: 'v1/get-practise-user-verification-details-by-status',
  getDoctorDetails: 'v2/get-user-details-doctor-verification-by-id',
  getDoctorVerificationStats: 'get-doctor-verification-stats',
  getRoleManagementStats: 'get-role-management-stats',
  getUserDetailsRoleManagement: 'detail',
  updateProfessionalDetailsVerificationStatus: 'update-professional-details-verification-status',
  updateIdDocumentsVerificationStatus: 'update-id-documents-verification-status',
  updateLivePictureVerificationStatus: 'update-live-picture-verification-status',
  updateOrgVerificationStatus: 'update-organisation-details-status',

  //ask for update
  askForUpdateProfessionalDetails: 'v2/ask-for-update-professional-details-verification-status',
  askForUpdateMedicalDocument: 'v2/ask-for-update-id-documents-verification-status',
  askForUpdateLivePicture: 'v2/ask-for-update-live-picture-verification-status',
  askForUpdateOrganisationDetails: 'v1/ask-for-update-organisation-details-verification-status',

  //reject
  rejectProfile: 'v1/reject-user-verification-profile',
  //overall
  updatePractiseOverAllVerificationStatus: 'update-practise-all-verification-status'
};
