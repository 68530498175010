import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ERRORS } from '../constants';

const handleAxiosError = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    if (!error.response) {
      return ERRORS.NETWORK_ERROR.MSG;
    }

    switch (error.response.status) {
      case 404:
        return ERRORS.NOT_FOUND.MSG;
      case 500:
      case 503:
        return ERRORS.SERVER_ERROR.MSG;
      default:
        return error.response.data?.message || error.message;
    }
  }

  console.error('Unexpected error:', error);
  return ERRORS.UNKNOWN_ERROR.MSG;
};

export const createApiThunk = <Arg = void, Result = any>(
  type: string,
  apiCall: (arg?: Arg) => Promise<Result>
) =>
  createAsyncThunk(type, async (arg: Arg | undefined, { rejectWithValue }) => {
    try {
      const result = await apiCall(arg);
      return result;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  });
